import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const CalendarPlus = ({ size = 24, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4375 8.4375V6.5625C14.4375 5.73407 13.7659 5.0625 12.9375 5.0625H5.0625C4.23407 5.0625 3.5625 5.73407 3.5625 6.5625V12.9375C3.5625 13.7659 4.23407 14.4375 5.0625 14.4375H8.4375M12.75 11.0625V14.4375M14.4375 12.75H11.0625M6 3.5625V6.1875M12 3.5625V6.1875M5.8125 8.0625H12.1875"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
