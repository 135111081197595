import ReactTooltip from "react-tooltip";
const PlatformBadge = ({
  name,
  logoUrl,
  size = "md",
  bgColor = "#000000",
  tooltipContent,
  tooltipDirection,
}: {
  name: string;
  logoUrl: string;
  size?: "md" | "sm";
  bgColor: string;
  tooltipContent: any;
  tooltipDirection?: "top" | "right" | "bottom" | "left";
}) => {
  let tooltipId = Math.floor(Math.random() * Date.now());

  const sizeConfig = {
    sm: { backgroundSize: "h-6 w-6", imageSize: "h-4 w-4" },
    md: { backgroundSize: "h-8 w-8", imageSize: "h-5 w-5" },
  };

  const { backgroundSize, imageSize } = sizeConfig[size] || sizeConfig.md;

  return (
    <>
      <ReactTooltip
        effect="solid"
        place={tooltipDirection || "top"}
        multiline={true}
        className="flat-color"
        id={tooltipId.toString()}
        backgroundColor="black"
      />
      <div
        className="cursor-pointer"
        data-for={tooltipId.toString()}
        data-tip={tooltipContent}
      >
        <div
          style={{ backgroundImage: `url(${logoUrl})` }}
          className={`${backgroundSize} bg-cover border-[.5px] border-neutral-300 rounded-full flex flex-col items-center justify-center`}
        ></div>
      </div>
    </>
  );
};

export default PlatformBadge;
