interface CardAssetContained {
  imageUrl: string;
  fillContainer?: boolean;
  background?: boolean;
  padding?: number;
}

const CardAssetContained = ({
  imageUrl,
  fillContainer = false,
  background = true,
  padding,
}: CardAssetContained) => {
  return (
    <span
      className={`${
        fillContainer ? "h-full" : "h-72"
      } w-full asset-image-container rounded-t-md ${
        background ? "bg-neutral-100 dark:bg-neutral-800" : null
      } ${
        padding ? `p-[${padding}]` : "p-8"
      } flex flex-col items-center justify-center`}
    >
      <img
        className={`shadow-xl contained-asset transform transition-all anim-loading bg-loading-light dark:bg-loading-dark ${fillContainer ? "" : "max-h-64"}`}
        src={imageUrl}
      ></img>
    </span>
  );
};

export default CardAssetContained;
