import * as React from "react";

interface Props extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const Live = ({ size = 12, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6" cy="6" r="3" fill="#03FD00" />
      <circle cx="6" cy="6" r="6" fill="#03FD00" fillOpacity="0.2" />
    </svg>
  );
};

export default Live;
