import { getBaseUrl } from "@/client/lib/links";
import Head from "next/head";

export type MetadataHead = {
  title?: string;
  description?: string;
  imageUrl?: string;
  useFallbackDescription?: boolean;
  canonicalUrl?: string;
};

const defaultTitle = "Art Blocks | Generative digital art";
const defaultDescription =
  "	Art Blocks is dedicated to bringing compelling works of generative digital art to life.";
const defaultImage =
  "https://res.cloudinary.com/art-blocks/image/upload/v1666111798/webclip_rvbyst.png";

export const MetadataHead = ({
  title = defaultTitle,
  description = defaultDescription,
  imageUrl = defaultImage,
  useFallbackDescription = false,
  canonicalUrl,
}: MetadataHead) => {
  const fallbackDescription = useFallbackDescription ? "" : defaultDescription;

  return (
    <Head>
      {/* titles */}
      <title>{title ? title : defaultTitle}</title>
      <meta key="name" itemProp="name" content={title ? title : defaultTitle} />
      <meta property="og:title" content={title ? title : defaultTitle} />
      <meta name="twitter:title" content={title ? title : defaultTitle} />

      {/* Descriptions */}
      <meta
        name="description"
        content={description ? description : fallbackDescription}
      />
      <meta
        itemProp="description"
        content={description ? description : fallbackDescription}
      />
      <meta
        name="twitter:description"
        content={description ? description : fallbackDescription}
      />

      <meta
        property="og:description"
        content={description ? description : fallbackDescription}
      />

      {/* Images */}
      <meta itemProp="image" content={imageUrl ? imageUrl : defaultImage} />
      <meta property="og:image" content={imageUrl ? imageUrl : defaultImage} />
      <meta name="twitter:image" content={imageUrl ? imageUrl : defaultImage} />

      <meta property="og:url" content={getBaseUrl()} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Art Blocks" />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <meta name="apple-mobile-web-app-title" content="Art Blocks" />

      {/* Canonical urls */}
      {canonicalUrl ? (
        <link rel="canonical" href={canonicalUrl} key="canonical" />
      ) : null}
    </Head>
  );
};

export default MetadataHead;
