import React from "react";

/**
 * A hook that formats a given date using the provided formatter.
 *
 * @param date - The date to format, as a string, Date object, or undefined.
 * @param formatter - An Intl.DateTimeFormat object to use for formatting.
 * @returns The formatted date string.
 *
 * @remarks
 * This hook uses useEffect to perform the date formatting on the client side.
 * This approach helps prevent server-client mismatches that can occur due to
 * different time zones between the server and client. By formatting the date
 * in the client's browser, we ensure that the displayed date is consistent
 * with the user's local time zone and formatting preferences.
 */
export function useFormattedDate(
  date: string | Date | undefined,
  formatter: Intl.DateTimeFormat
) {
  const [formattedDate, setFormattedDate] = React.useState<string>("");

  React.useEffect(() => {
    if (!date) {
      setFormattedDate("");
      return;
    }

    setFormattedDate(formatter.format(new Date(date)));
  }, [date, formatter]);

  return formattedDate;
}
